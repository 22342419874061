import Vue from "vue";
import VueI18n, {LocaleMessages} from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

export function getCookie(cookieName: string): string | null {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie?.split(";") ?? "";
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

function getCulture(): string {
    const decodedCookie = getCookie("Culture");
    const lang = decodedCookie?.split("|")[0].split("=")[1] ?? "en-GB";
    document.documentElement.lang = lang;
    return lang;
}

const i18n = new VueI18n({
    locale: getCulture(),
    fallbackLocale: "en-GB",
    messages: loadLocaleMessages(),
    silentFallbackWarn: true,
    silentTranslationWarn: true
});

export default i18n;
