import Vue from "vue";
import Vuex from "vuex";

import Identity from "@/store/identity";
import CacheInvalidation from "@/store/cache";
import ClientSettings from "@/store/clientSettings";
import Features from "@/store/features";
import Settings from "@/store/settings";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    modules: {
        Identity,
        CacheInvalidation,
        ClientSettings,
        Features,
        Settings
    }
});

export default store;
