import {Guid} from "guid-typescript";
import {getRequest, putRequest} from "@/api/HttpMethods";

export const SettingsApi = {
    get(clientId: Guid): Promise<ISettingEdit> {
        return getRequest(getBaseUrl(clientId));
    },
    getEnabled(clientId: Guid): Promise<string[]> {
        return getRequest(`${getBaseUrl(clientId)}/enabled`);
    },
    set(clientId: Guid, flags: { [name: string]: ISettingsStatus }): Promise<void> {
        return putRequest(getBaseUrl(clientId), flags);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/settings`;
}

export interface ISettingEdit {
    available: {
        [name: string]: ISettingsObject;
    };
    client: {
        [name: string]: ISettingsStatus;
    };
}


export interface ISettingsObject {
    label: string;
    description: string;
    status: ISettingsStatus;
}

export interface ISettingsStatus {
    enabled: boolean;
}
