
import {Vue, Component} from "vue-property-decorator";
import {Getter} from "vuex-class";

@Component
export default class Helper extends Vue {
    @Getter getPermissions!: string[];
    @Getter getFeatures!: string[];
    @Getter getSettings!: string[];

    permissionCheck(permission: string): boolean {
        return this.getPermissions.includes(permission);
    }

    featureCheck(feature: string): boolean {
        return this.getFeatures.includes(feature);
    }

    settingsCheck(setting: string): boolean {
        return this.getSettings.includes(setting);
    }
}
