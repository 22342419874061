


















import {Component, Prop, Vue} from "vue-property-decorator";
import { Portal } from "@linusborg/vue-simple-portal";
import IconButton from "@/components/helpers/IconButton.vue";
import Icon from "@/components/helpers/Icon.vue";

@Component({components: {Icon, IconButton, Portal}})
export default class Toast extends Vue {
    @Prop({type: String}) readonly message!: string;
    @Prop({type: String}) readonly title!: string;
    @Prop({type: String, default: "primary"}) readonly variant!: string;
    @Prop({type: Number, default: 5000}) readonly autoHideDelay!: number;
    @Prop({type: Boolean, default: false}) readonly noAutoHide!: boolean;
    @Prop({type: Boolean, default: false}) readonly noCloseButton!: boolean;

    show = true;

    get classes() {
        return {
            "b-toast-primary": this.variant === "primary",
            "b-toast-secondary": this.variant === "secondary",
            "b-toast-warning": this.variant === "warning",
            "b-toast-danger": this.variant === "danger",
        };
    }

    created() {
        if (!this.noAutoHide) {
            setTimeout(() => {
                this.show = false;
            }, this.autoHideDelay);
        }
    }
}
