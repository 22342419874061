import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Guid} from "guid-typescript";
import {SettingsApi} from "@/api/v1/SettingsApi";

@Module
class Settings extends VuexModule {
    settings: string[] = [];

    @Mutation
    setSettings(settings: string[]) {
        this.settings = settings;
    }

    @Action({ commit: "setSettings"})
    retrieveSettings(clientId: Guid) {
        return SettingsApi.getEnabled(clientId);
    }

    get getSettings(): string[] {
        return this.settings;
    }
}

export default Settings;
