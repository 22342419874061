















import {Vue, Component} from "vue-property-decorator";
import {Client} from "@/api/v1/ClientApi";
import {Getter} from "vuex-class";

@Component
export default class AppFooter extends Vue {
    @Getter getClient!: Client;
}
