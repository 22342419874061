
















import {Vue, Component, Prop} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";

@Component({components: {Icon}})
export default class MobileOnlyAccordion extends Vue {
    @Prop({type: String}) readonly title!: string;
    @Prop({type: String, default: "700"}) readonly maxWidth!: string;
    @Prop({type: Boolean, default: false}) readonly dContents!: string;

    isMobile = false;
    isOpen = false;

    toggleDetailsElement(event) {
        this.isOpen = event.target.open;
    }

    created() {
        const query = window.matchMedia(`(max-width: ${this.maxWidth}px)`);
        this.isMobile = query.matches;
        query.addEventListener("change", () => {
            this.isMobile = query.matches;
        });
    }
}
