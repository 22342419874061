import Vue from "vue";
import EmptyRouterView from "@/EmptyRouterView.vue";
import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import "@/registerServiceWorker";
import * as Sentry from "@sentry/browser";
import {Vue as VueIntegration} from "@sentry/integrations";
import {Integrations} from "@sentry/tracing";
import Toast from "@/components/Toast.vue";
import VueI18n from "vue-i18n";
import FormGroup from "@/components/FormGroup.vue";
import ClickOutside from "vue-click-outside";
import TextareaAutosize from "vue-textarea-autosize";
import TranslateResult = VueI18n.TranslateResult;

Vue.config.productionTip = false;

Vue.component("FormGroup", FormGroup);
Vue.directive("ClickOutside", ClickOutside);
// @ts-ignore
Vue.use(TextareaAutosize);

new Vue({
    router,
    store,
    i18n,
    el: "#app",
    render: h => h(EmptyRouterView)
});

const methods = {
    toast: (message: string | TranslateResult, options) => {
        const defaultOptions = {
            message: message.toString()
        };

        const propsData = Object.assign({}, defaultOptions, options);

        return new (Vue.extend(Toast))({
            el: document.createElement("div"),
            propsData
        });
    }
};

Vue.$bvToast = methods;
Vue.prototype.$bvToast = methods;

const env = process.env.NODE_ENV;

if (env === "production") {
    Sentry.init({
        environment: env,
        dsn: "https://2e965c3eb3124b73b850f41faa6b2a96@o463432.ingest.sentry.io/5527089",
        integrations: [
            new Integrations.BrowserTracing(),
            new VueIntegration({
                Vue,
                attachProps: true,
                tracing: true,
                // @ts-ignore
                tracingOptions: {
                    trackComponents: true,
                }
            })
        ],
        tracesSampleRate: 0.1
    });
}

