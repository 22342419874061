import {Guid} from "guid-typescript";
import {getRequest, putRequest} from "@/api/HttpMethods";

export const FeatureApi = {
    get(clientId: Guid): Promise<IFeatureFlagEdit> {
        return getRequest(getBaseUrl(clientId));
    },
    getEnabled(clientId: Guid): Promise<string[]> {
        return getRequest(`${getBaseUrl(clientId)}/enabled`);
    },
    set(clientId: Guid, flags: { [name: string]: IFeatureStatus }): Promise<void> {
        return putRequest(getBaseUrl(clientId), flags);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/features`;
}

export interface IFeatureFlagEdit {
    global: {
        [name: string]: IFeatureObject;
    };
    client: {
        [name: string]: IFeatureStatus;
    };
}

export interface IFeatureObject {
    label: string;
    description: string;
    status: IFeatureStatus;
}

export interface IFeatureStatus {
    enabled: boolean | null;
    force?: boolean;
}
