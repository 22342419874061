































import {Vue, Component, Prop} from "vue-property-decorator";
import Icon from "@/components/helpers/Icon.vue";

@Component({components:{Icon}})
export default class IconButton extends Vue {
    @Prop({type: String, default: "secondary"}) readonly variant!: string;
    @Prop({type: Boolean, default: false}) readonly pill!: boolean;
    @Prop({type: Boolean, default: true}) readonly round!: boolean;
    @Prop({type: String, required: false}) readonly icon!: string;
    @Prop({type: Boolean, default: false}) readonly disabled!: boolean;
    @Prop({type: String}) readonly href!: string;

    get classes() {
        return {
            rounded: this.round,
            "btn-primary": this.variant === "primary",
            "btn-secondary": this.variant === "secondary",
            "btn-warning": this.variant === "warning",
            "btn-danger": this.variant === "danger",
        };
    }
}
