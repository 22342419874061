import {getRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";

export const ClientApi = {
    find(query: IQueryRequest): Promise<IPaginatedList<Client>> {
        return getRequest("/internal/api/v1/clients", query);
    },
    findById(id: Guid): Promise<Client> {
        return getRequest(`/internal/api/v1/clients/${id}`);
    }
};

export interface Client {
    id: Guid;
    name: string;
    email: string;
    logo: string;
}

export function emptyClient(): Client {
    return {
        id: Guid.createEmpty(),
        name: "",
        email: "",
        logo: ""
    };
}
