


























import {Component, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {UserIdentity} from "@/store/types";
import TopNav from "@/components/TopNav.vue";
import {Route} from "vue-router";
import AppFooter from "@/components/AppFooter.vue";
import ServiceUnavailable from "@/views/ServiceUnavailable.vue";
import Toast from "@/components/Toast.vue";

@Component({
    components: {ServiceUnavailable, TopNav, AppFooter, Toast},
    beforeRouteUpdate (to, from, next) {
        //@ts-ignore
        this.lastRoute = from;
        next();
    }
})
export default class App extends Vue {
    @Getter getIdentity!: UserIdentity;

    lastRoute: Route | null = null;
    offline = !window.navigator.onLine;
    newContent = false;
    registrationContext: ServiceWorkerRegistration | null = null;

    get showUnavailable(): boolean {
        return this.getIdentity.name === "ERROR";
    }

    closeModal() {
        const getReturnLocation = this.$route.meta["getReturnLocation"];
        if (this.lastRoute && !this.$route.query["noReturn"]) {
            this.$router.go(-1);
        } else if (getReturnLocation) {
            this.$router.replace(getReturnLocation(this.$route));
        }
    }

    refresh() {
        if (this.registrationContext) {
            this.newContent = false;
            this.registrationContext.update();
            window.location.reload();
        } else {
            window.location.reload();
        }
    }

    created() {
        window.addEventListener("online", () => {
            this.offline = false;
        });
        window.addEventListener("offline", () => {
            this.offline = true;
        });

        window.addEventListener("orderview.updated", (event: Event) => {
            this.newContent = true;
            this.registrationContext = (event as CustomEvent).detail.registration;
        });
    }
}
