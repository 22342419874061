













import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class FormGroup extends Vue {
    @Prop({type: String}) readonly label!: string;
}
