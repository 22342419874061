import {getRequest, postRequest} from "@/api/HttpMethods";
import {SessionData} from "@/store/types";

export const AccountApi = {
    auth(code: string): Promise<any> {
        return postRequest(`${getBaseUrl()}/auth`, {code});
    },
    getSession(): Promise<SessionData> {
        return getRequest(`${getBaseUrl()}/identity`);
    },
    getAuthSettings(): Promise<{ clientId: string; url: string }> {
        return getRequest(`${getBaseUrl()}/auth/settings`);
    },
    logout(): Promise<void> {
        return postRequest(`${getBaseUrl()}/logout`);
    }
};

function getBaseUrl(): string {
    return "/internal/api/v1/account";
}
