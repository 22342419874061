import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class CacheInvalidation extends VuexModule {
    cache: TimestampCache = {
        products: null,
        productVariations: null,
        carriers: null,
        clients: null,
        users: null,
        orderSources: null,
        addresses: null,
        parcels: null,
        packaging: null,
        mfa: null,
        templates: null,
        order: null,
        orderItems: null,
        parcel: null,
        skuMaps: null,
        courierMaps: null,
        apiTokens: null,
        carrierServices: null,
    };

    get tstpCache(): TimestampCache {return this.cache;}

    @Mutation setProducts(date: Date) {this.cache.products = date;}
    @Mutation setProductVariations(date: Date) {this.cache.productVariations = date;}
    @Mutation setCarriers(date: Date) {this.cache.carriers = date;}
    @Mutation setClients(date: Date) {this.cache.clients = date;}
    @Mutation setUsers(date: Date) {this.cache.users = date;}
    @Mutation setOrderSources(date: Date) {this.cache.orderSources = date;}
    @Mutation setAddresses(date: Date) {this.cache.addresses = date;}
    @Mutation setParcels(date: Date) {this.cache.parcels = date;}
    @Mutation setPackaging(date: Date) {this.cache.packaging = date;}
    @Mutation setMfa(date: Date) {this.cache.mfa = date;}
    @Mutation setTemplates(date: Date) {this.cache.templates = date;}
    @Mutation setOrder(date: Date) {this.cache.order = date;}
    @Mutation setOrderItems(date: Date) {this.cache.orderItems = date;}
    @Mutation setParcel(date: Date) {this.cache.parcel = date;}
    @Mutation setSkuMaps(date: Date) {this.cache.skuMaps = date;}
    @Mutation setCourierMaps(date: Date) {this.cache.courierMaps = date;}
    @Mutation setApiTokens(date: Date) {this.cache.apiTokens = date;}
    @Mutation setCarrierServices(date: Date) {this.cache.carrierServices = date;}

    @Action({commit: "setProducts"}) invalidateProducts() {return new Date();}
    @Action({commit: "setProductVariations"}) invalidateProductVariations() {return new Date();}
    @Action({commit: "setCarriers"}) invalidateCarriers() {return new Date();}
    @Action({commit: "setClients"}) invalidateClients() {return new Date();}
    @Action({commit: "setUsers"}) invalidateUsers() {return new Date();}
    @Action({commit: "setOrderSources"}) invalidateOrderSources() {return new Date();}
    @Action({commit: "setAddresses"}) invalidateAddresses() {return new Date();}
    @Action({commit: "setParcels"}) invalidateParcels() {return new Date();}
    @Action({commit: "setPackaging"}) invalidatePackaging() {return new Date();}
    @Action({commit: "setMfa"}) invalidateMfa() {return new Date();}
    @Action({commit: "setTemplates"}) invalidateTemplates() {return new Date();}
    @Action({commit: "setOrder"}) invalidateOrder() {return new Date();}
    @Action({commit: "setOrderItems"}) invalidateOrderItems() {return new Date();}
    @Action({commit: "setParcel"}) invalidateParcel() {return new Date();}
    @Action({commit: "setSkuMaps"}) invalidateSkuMaps() {return new Date();}
    @Action({commit: "setCourierMaps"}) invalidateCourierMaps() {return new Date();}
    @Action({commit: "setApiTokens"}) invalidateApiTokens() {return new Date();}
    @Action({commit: "setCarrierServices"}) invalidateCarrierServices() {return new Date();}
}

export interface TimestampCache {
    products: Date | null;
    productVariations: Date | null;
    carriers: Date | null;
    clients: Date | null;
    users: Date | null;
    orderSources: Date | null;
    addresses: Date | null;
    parcels: Date | null;
    packaging: Date | null;
    mfa: Date | null;
    templates: Date | null;
    order: Date | null;
    orderItems: Date | null;
    parcel: Date | null;
    skuMaps: Date | null;
    courierMaps: Date | null;
    apiTokens: Date | null;
    carrierServices: Date | null;
}
