import {SessionData, UserIdentity} from "@/store/types";
import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Guid} from "guid-typescript";
import {AccountApi} from "@/api/v1/AccountApi";
import {captureException} from "@sentry/browser";

@Module
class Identity extends VuexModule {
    identity: UserIdentity = {
        id: Guid.createEmpty(),
        clientId: Guid.createEmpty(),
        name: "",
        email: "",
        role: 0,
        culture: "en-GB",
        isAuthenticated: false
    };
    impersonation: Impersonation = {
        active: false,
        actualClientId: Guid.createEmpty()
    }
    permissions: string[] = [];

    @Mutation
    setIdentity(identity: UserIdentity) {
        this.identity = identity;
    }

    @Mutation
    setSessionData({user, permissions}: SessionData) {
        this.identity = user;
        this.permissions = permissions;
    }

    @Mutation
    setImpersonating(impersonation: Impersonation) {
        this.impersonation = impersonation;
    }

    @Action({ commit: "setSessionData"})
    retrieveSessionData() {
        return AccountApi.getSession()
            .then(data => {
                if (this.getImpersonation.active) {
                    return {
                        user: {
                            ...data.user,
                            clientId: this.getIdentity.clientId
                        },
                        permissions: data.permissions
                    };
                } else {
                    return data;
                }
            })
            .catch(err => {
                captureException(err);

                return {
                    user: {
                        clientId: Guid.createEmpty(),
                        id: Guid.createEmpty(),
                        role: 1,
                        email: "",
                        name: "ERROR",
                        culture: "en-GB",
                        isAuthenticated: false
                    },
                    permissions: []
                };
            });
    }

    @Action({ commit: "setIdentity"})
    updateEmail(email: string): UserIdentity {
        return {
            clientId: Guid.createEmpty(),
            id: Guid.createEmpty(),
            role: 1,
            email: email,
            name: "",
            culture: "en-GB",
            isAuthenticated: false
        };
    }

    @Action({ commit: "setIdentity"})
    updateIdentity(identity: UserIdentity): UserIdentity {
        if (this.getImpersonation.active) {
            return {
                ...identity,
                clientId: this.getIdentity.clientId
            };
        } else {
            return identity;
        }
    }

    @Action({ commit: "setPermissions"})
    updatePermissions(permissions: string[]): string[] {
        return permissions;
    }

    @Action({ commit: "setIdentity"})
    updateClientId(clientId: Guid): UserIdentity {
        if (this.getIdentity.role === 9001) {
            return {
                ...this.getIdentity,
                clientId
            };
        }
        return this.getIdentity;
    }

    @Action({ commit: "setImpersonating"})
    updateImpersonation(impersonating: boolean): Impersonation {
        if (this.getIdentity.role === 9001) {
            return {
                active: impersonating,
                actualClientId: this.getIdentity.clientId
            };
        }
        return {
            active: false
        };
    }

    get getIdentity(): UserIdentity {
        return this.identity;
    }

    get getImpersonation(): Impersonation {
        return this.impersonation;
    }

    get getPermissions(): string[] {
        return this.permissions;
    }
}

export interface Impersonation {
    active: boolean;
    actualClientId?: Guid;
}

export default Identity;
