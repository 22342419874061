import {IQueryRequest} from "./Utils";
import {setTag} from "@sentry/browser";

export function postRequest(url: string, data: any = {}): Promise<any> {
    return makeRequest("POST", url, data);
}

export function postFilesRequest(url: string, files: File[]): Promise<any> {
    const formData = new FormData();

    files.forEach(f => {
        formData.append(f.name, f);
    });

    const headers: any = { "Accept": "application/json" };

    const requestInit: RequestInit = {
        method: "POST",
        headers: headers,
        body: formData
    };

    return fetch(url, requestInit).then(handleResponse);
}

export function getRequest(url: string, queryRequest: IQueryRequest | null = null): Promise<any> {
    if (queryRequest != null) {
        if (url.indexOf("?") == -1) url += "?";
        else url += "&";

        url += queryRequest.toQueryString();
    }

    return makeRequest("GET", url);
}

export function putRequest(url: string, data: any = {}): Promise<any> {
    return makeRequest("PUT", url, data);
}

export function deleteRequest(url: string, data: any = {}): Promise<any> {
    return makeRequest("DELETE", url, data);
}

function makeRequest(method: string, url: string, data: any = {}): Promise<any> {
    const headers: any = { "Accept": "application/json" };

    if (method != "GET")
        headers["Content-Type"] = "application/json";

    const requestInit: RequestInit = {
        method: method,
        headers: headers
    };

    if (method != "GET")
        requestInit.body = JSON.stringify(data);

    return fetch(url, requestInit).then(handleResponse);
}

function handleResponse(response: Response): Promise<any> {
    const contentType = response.headers.get("content-type");
    if (contentType) {
        //Add other response types as necessary.
        if (contentType.includes("json"))
            return handleJSONResponse(response);
        else if (contentType.includes("text"))
            return handleTextResponse(response);
        else if (contentType.includes("pdf"))
            return handleBlobResponse(response);
        else
            throw new Error(`Content-Type ${contentType} not supported. Use the fetch API directly or create a handler.`);
    } else if (response.ok) {
        return Promise.resolve({
            success: true,
            status: response.status
        });
    } else {
        return Promise.reject(
            new RequestError(response.url, response.status, response.statusText, "No content-type for response")
        );
    }
}

function handleJSONResponse(response: Response): Promise<any> {
    return response.json()
        .then(json => {
            if (response.ok) return json;
            else {
                return Promise.reject(
                    new RequestError(response.url, response.status, response.statusText, json)
                );
            }
        });
}

function handleTextResponse(response: Response): Promise<any> {
    return response.text()
        .then((text: any) => {
            if (response.ok) return text;
            else {
                return Promise.reject(
                    new RequestError(response.url, response.status, response.statusText, text)
                );
            }
        });
}

function handleBlobResponse(response: Response): Promise<any> {
    return response.blob()
        .then((blob: any) => {
            if (response.ok) return blob;
            else {
                return Promise.reject(
                    new RequestError(response.url, response.status, response.statusText, blob)
                );
            }
        });
}

export class RequestError extends Error {
    url: string;
    status: number;
    statusText: string;
    data: unknown;

    constructor(url: string, status: number, statusText: string, data: Record<string, unknown> | string) {
        super(`Request failed with status ${status}`);
        this.url = url;
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        if (typeof data === "object" && data.traceId && typeof data.traceId === "string") {
            setTag("server.traceId", data.traceId);
        }
        this.name = `${statusText}`;
        this.message += `\n${JSON.stringify(data)}`;
    }
}

