
















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
    @Prop({type: String, default: "Not Found"}) readonly title!: string;
}
