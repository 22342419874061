import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Guid} from "guid-typescript";
import {Client, ClientApi, emptyClient} from "@/api/v1/ClientApi";
import {captureException} from "@sentry/browser";

@Module
class ClientSettings extends VuexModule {
    client: Client = emptyClient();

    @Mutation
    setClient(client: Client) {
        this.client = client;
    }

    @Action({commit: "setClient"})
    retrieveClient(id: Guid, force: boolean) {
        if (this.getClient.id.toString() === Guid.EMPTY || force) {
            return ClientApi.findById(id)
                .catch(err => {
                    captureException(err);

                    return emptyClient();
                });
        } else {
            return this.getClient;
        }
    }

    @Action({commit: "setClient"})
    setEmptyClient() {
        return emptyClient();
    }

    get getClient(): Client {
        return this.client;
    }
}

export default ClientSettings;
