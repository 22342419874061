



















































































import {Component, Prop} from "vue-property-decorator";
import {AccountApi} from "@/api/v1/AccountApi";
import {UserIdentity} from "@/store/types";
import {Action, Getter} from "vuex-class";
import {Client} from "@/api/v1/ClientApi";
import {Impersonation} from "@/store/identity";
import Icon from "@/components/helpers/Icon.vue";
import Helper from "@/components/helpers/Helper.vue";
import MobileOnlyAccordion from "@/components/MobileOnlyAccordion.vue";

@Component({
    components: {MobileOnlyAccordion, Icon},
})
export default class TopNav extends Helper {
    @Prop({type: Boolean, default: false}) readonly offline!: boolean;

    @Getter getIdentity!: UserIdentity;
    @Getter getImpersonation!: Impersonation;
    @Getter getClient!: Client;

    @Action updateClientId!: Function;
    @Action updateImpersonation!: Function;

    permissionCheckAny(permissions: string[]): boolean {
        return this.getPermissions.some(p => permissions.includes(p));
    }

    toggleDropdown() {
        const dropdown = this.$refs.dropdown as HTMLElement;
        dropdown.classList.toggle("show");
        dropdown.lastElementChild?.classList.toggle("show");
    }

    closeDropdown() {
        const dropdown = this.$refs.dropdown as HTMLElement;
        dropdown.classList.remove("show");
        dropdown.lastElementChild?.classList.remove("show");
    }

    async logout() {
        await AccountApi.logout()
            .then(() => {
                this.$router.push({name: "login"});
            });
    }

    async disableImpersonation() {
        if (this.getImpersonation.active) {
            await this.updateClientId(this.getImpersonation.actualClientId);
            await this.updateImpersonation(false);
            location.reload();
        }
    }
}
