import Vue from "vue";
import VueRouter, {NavigationGuardNext, Route} from "vue-router";
import {Component} from "vue-property-decorator";
import Routes from "@/router/Routes";
import store from "@/store";
import App from "@/App.vue";
import {SessionData} from "@/store/types";
import {Guid} from "guid-typescript";
import NotFound from "@/views/NotFound.vue";
import nProgress from "nprogress";

Vue.use(VueRouter);

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate"
]);

const routes = [
    {
        path: "/",
        component: App,
        children: Routes("/", (route, params) => {
            if (!params) {
                params = {};
            }
            params["clientId"] = store.getters.getIdentity.clientId.toString();
            return params;
        }).concat([{
            path: "/*",
            components: {page: NotFound}
        }])
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        else return { x: 0, y: 0 };
    }
});

//TODO rename this
const thing = ({user: identity, permissions}: SessionData, to: Route, from: Route, next: NavigationGuardNext) => {
    const isAuthenticated = identity.isAuthenticated;
    const requireAnonymous = to.meta["requireAnonymous"];
    if (isAuthenticated) {
        const requiredPermission = to.meta["requiredPermission"];

        if (requireAnonymous && requireAnonymous === true) {
            next({name: "overview"});
        } else if (requiredPermission && !permissions.includes(requiredPermission)) {
            next({name: "overview"});
        } else {
            next();
        }
    } else {
        if (to.meta["allowAnonymous"] || requireAnonymous) {
            next();
        } else {
            next({name: "login"});
        }
    }
};

let timeoutHandler: NodeJS.Timeout | null = null;

router.afterEach(() => {
    nProgress.done();
    if (timeoutHandler) {
        clearTimeout(timeoutHandler);
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.title != null) document.title = "OrderView - " + to.meta.title;
    else document.title = "OrderView";
    if (to.name) {
        // Start the route progress bar.
        timeoutHandler = setTimeout(() => {
            if (!nProgress.isStarted())
                nProgress.start();
        }, 400);
    }
    const session = await store.dispatch("retrieveSessionData");
    if (session.user.name === "ERROR") {
        next({hash: "test"});
    } else {
        if (session.user.clientId.toString() === Guid.EMPTY) {
            await store.dispatch("setEmptyClient");
            thing(session, to, from, next);
        } else {
            store.dispatch("retrieveSettings", session.user.clientId);
            store.dispatch("retrieveFeatures", session.user.clientId);
            await store.dispatch("retrieveClient", session.user.clientId);
            thing(session, to, from, next);
        }
    }
});

export default router;
