import {RawLocation, Route, RouteConfig} from "vue-router";

//Unauthenticated
const Login = () => import(/* webpackChunkName: "unauthenticated" */ "@/views/authentication/Login.vue");

//Standard
const Overview = () => import(/* webpackChunkName: "standard" */ "@/views/Overview.vue");
const OrderTable = () => import(/* webpackChunkName: "standard" */ "@/views/orders/OrderTable.vue");
const OrderOverview = () => import(/* webpackChunkName: "standard" */ "@/views/orders/OrderOverview.vue");
const CreateOrder = () => import(/* webpackChunkName: "standard" */ "@/views/orders/CreateOrder.vue");
const UpdateOrder = () => import(/* webpackChunkName: "standard" */ "@/views/orders/UpdateOrder.vue");
const ParcelOverview = () => import(/* webpackChunkName: "standard" */ "@/views/parcels/ParcelOverview.vue");
const CreateParcel = () => import(/* webpackChunkName: "standard" */ "@/views/parcels/CreateParcel.vue");
const UpdateParcel = () => import(/* webpackChunkName: "standard" */ "@/views/parcels/UpdateParcel.vue");
const ShipParcel = () => import(/* webpackChunkName: "standard" */ "@/views/parcels/ShipParcel.vue");
const LegacyProductTable = () => import(/* webpackChunkName: "standard" */ "@/views/products/LegacyProductTable.vue");
const ProductTable = () => import(/* webpackChunkName: "standard" */ "@/views/products/ProductTable.vue");
const CreateOrUpdateProduct = () => import(/* webpackChunkName: "standard" */ "@/views/products/CreateOrUpdateProduct.vue");
const ManageLegacyProduct = () => import(/* webpackChunkName: "standard" */ "@/views/products/ManageLegacyProduct.vue");
const PackagingTable = () => import(/* webpackChunkName: "standard" */ "@/views/packaging/PackagingTable.vue");
const CreateOrUpdatePackaging = () => import(/* webpackChunkName: "standard" */ "@/views/packaging/CreateOrUpdatePackaging.vue");

//Management
const Settings = () => import(/* webpackChunkName: "manager" */ "@/views/settings/Settings.vue");
const TemplateTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/templates/TemplateTable.vue");
const CreateOrUpdateTemplate = () => import(/* webpackChunkName: "manager" */ "@/views/settings/templates/CreateOrUpdateTemplate.vue");
const AddressTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/addresses/AddressTable.vue");
const CreateOrUpdateAddress = () => import(/* webpackChunkName: "manager" */ "@/views/settings/addresses/CreateOrUpdateAddress.vue");
const OrderSourceTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/order-sources/OrderSourceTable.vue");
const SelectOrderSourceType = () => import(/* webpackChunkName: "manager" */ "@/views/settings/order-sources/SelectOrderSourceType.vue");
const CreateOrUpdateOrderSource = () => import(/* webpackChunkName: "manager" */ "@/views/settings/order-sources/CreateOrUpdateOrderSource.vue");
const AuthOrderSource = () => import(/* webpackChunkName: "manager" */ "@/views/settings/order-sources/AuthOrderSource.vue");
const CarrierTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/CarrierTable.vue");
const SelectCarrierType = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/SelectCarrierType.vue");
const CreateOrUpdateCarrier = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/CreateOrUpdateCarrier.vue");
const AuthCarrier = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/AuthCarrier.vue");
const ManageCarrierServices = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/clickanddrop/ManageCarrierServices.vue");
const CreateOrUpdateCarrierService = () => import(/* webpackChunkName: "manager" */ "@/views/settings/carriers/clickanddrop/CreateOrUpdateCarrierService.vue");
const ManageShippingInfo = () => import(/* webpackChunkName: "manager" */ "@/views/settings/ManageShippingInfo.vue");
const SkuMapTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/sku-maps/SkuMapTable.vue");
const CreateSkuMap = () => import(/* webpackChunkName: "manager" */ "@/views/settings/sku-maps/CreateSkuMap.vue");
const CourierMapTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/courier-maps/CourierMapTable.vue");
const CreateCourierMap = () => import(/* webpackChunkName: "manager" */ "@/views/settings/courier-maps/CreateCourierMap.vue");
const ApiTokenTable = () => import(/* webpackChunkName: "manager" */ "@/views/settings/api-tokens/ApiTokenTable.vue");
const CreateOrUpdateApiToken = () => import(/* webpackChunkName: "manager" */ "@/views/settings/api-tokens/CreateOrUpdateApiToken.vue");
const Usages = () => import(/* webpackChunkName: "manager" */ "@/views/settings/Usages.vue");
const ManageSettings = () => import(/* webpackChunkName: "manager" */ "@/views/settings/ManageSettings.vue");
const Metrics = () => import(/* webpackChunkName: "manager" */ "@/views/metrics/Metrics.vue");

//Admin
const Admin = () => import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue");
const ClientTable = () => import(/* webpackChunkName: "admin" */ "@/views/admin/clients/ClientTable.vue");
const ManageFeatureFlags = () => import(/* webpackChunkName: "admin" */ "@/views/admin/clients/ManageFeatureFlags.vue");
const AdminTemplateTable = () => import(/* webpackChunkName: "admin" */ "@/views/admin/templates/AdminTemplateTable.vue");
const ManageTemplateAccess = () => import(/* webpackChunkName: "admin" */ "@/views/admin/templates/ManageTemplateAccess.vue");

export default (routePrefix: string, addClientId: (route: Route, params?: { [key: string]: string | number | boolean }) => Record<string, any>): RouteConfig[] => [
    {
        path: routePrefix,
        name: "overview",
        components: {page: Overview},
        props: {page: (route: Route) => addClientId(route, {})}
    },
    {
        path: routePrefix + "metrics",
        name: "metrics",
        components: {page: Metrics},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "metrics:read"
        }
    },
    {
        path: routePrefix + "orders",
        name: "orders",
        components: {page: OrderTable},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "order:read",
        }
    },
    {
        path: routePrefix + "orders/create",
        name: "createOrder",
        components: {page: CreateOrder},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "order:create",
        }
    },
    {
        path: routePrefix + "orders/:orderId",
        name: "orderOverview",
        components: {page: OrderOverview},
        props: {page: (route: Route) => addClientId(route, {orderId: route.params.orderId})},
        meta: {
            requiredPermission: "order:read",
        }
    },
    {
        path: routePrefix + "orders/:orderId/update",
        name: "updateOrder",
        components: {page: OrderOverview, modal: UpdateOrder},
        props: {
            page: (route: Route) => addClientId(route, {orderId: route.params.orderId}),
            modal: (route: Route) => addClientId(route, {orderId: route.params.orderId}),
        },
        meta: {
            requiredPermission: "order:update",
            showModal: true,
            getReturnLocation(): RawLocation {
                return {name: "orderOverview"};
            }
        }
    },
    {
        path: routePrefix + "orders/:orderId/parcels/create",
        name: "createParcel",
        components: {page: OrderOverview, modal: CreateParcel},
        props: {
            page: (route: Route) => addClientId(route, {orderId: route.params.orderId}),
            modal: (route: Route) => addClientId(route, {orderId: route.params.orderId})
        },
        meta: {
            requiredPermission: "parcel:create",
            showModal: true,
            getReturnLocation(): RawLocation {
                return {name: "orderOverview"};
            }
        }
    },
    {
        path: routePrefix + "orders/:orderId/parcels/:parcelId/ship",
        name: "ship",
        components: {page: ShipParcel},
        props: {
            page: (route: Route) => addClientId(route, {
                orderId: route.params.orderId,
                parcelId: route.params.parcelId
            })
        },
        meta: {
            requiredPermission: "parcel:update",
        }
    },
    {
        path: routePrefix + "orders/:orderId/parcels/:parcelId/update",
        name: "updateParcel",
        components: {page: ParcelOverview, modal: UpdateParcel},
        props: {
            page: (route: Route) => addClientId(route, {orderId: route.params.orderId, parcelId: route.params.parcelId}),
            modal: (route: Route) => addClientId(route, {orderId: route.params.orderId, parcelId: route.params.parcelId}),
        },
        meta: {
            requiredPermission: "parcel:update",
            getReturnLocation(): RawLocation {
                return {name: "parcelOverview"};
            }
        }
    },
    {
        path: routePrefix + "orders/:orderId/parcels/:parcelId",
        name: "parcelOverview",
        components: {page: ParcelOverview},
        props: {
            page: (route: Route) => addClientId(route, {
                orderId: route.params.orderId,
                parcelId: route.params.parcelId
            })
        },
        meta: {
            requiredPermission: "parcel:read",
        }
    },
    {
        path: routePrefix + "packaging",
        name: "packaging",
        components: {page: PackagingTable},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "packaging:read",
        }
    },
    {
        path: routePrefix + "packaging/create",
        name: "createPackaging",
        components: {page: PackagingTable, modal: CreateOrUpdatePackaging},
        props: {
            page: (route: Route) => addClientId(route, {}),
            modal: (route: Route) => addClientId(route, {})
        },
        meta: {
            requiredPermission: "packaging:create",
            getReturnLocation(): RawLocation {
                return {name: "packaging"};
            }
        }
    },
    {
        path: routePrefix + "packaging/:packagingId/update",
        name: "updatePackaging",
        components: {page: PackagingTable, modal: CreateOrUpdatePackaging},
        props: {
            page: (route: Route) => addClientId(route, {}),
            modal: (route: Route) => addClientId(route, {packagingId: route.params.packagingId})
        },
        meta: {
            requiredPermission: "packaging:update",
            getReturnLocation(): RawLocation {
                return {name: "packaging"};
            }
        }
    },
    {
        path: routePrefix + "products",
        name: "products",
        components: {page: ProductTable},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "product:read",
        }
    },
    {
        path: routePrefix + "products/create",
        name: "createProduct",
        components: {page: ProductTable, modal: CreateOrUpdateProduct},
        props: {
            page: (route: Route) => addClientId(route, {}),
            modal: (route: Route) => addClientId(route, {})
        },
        meta: {
            requiredPermission: "product:create",
            getReturnLocation(): RawLocation {
                return routePrefix + "products";
            }
        }
    },
    {
        path: routePrefix + "products/:productId/update",
        name: "updateProduct",
        components: {page: ProductTable, modal: CreateOrUpdateProduct},
        props: {
            page: (route: Route) => addClientId(route, {}),
            modal: (route: Route) => addClientId(route, {productId: route.params.productId}),
        },
        meta: {
            requiredPermission: "product:update",
            getReturnLocation(): RawLocation {
                return routePrefix + "products";
            }
        }
    },
    {
        path: routePrefix + "legacy-products",
        name: "legacy-products",
        components: {page: LegacyProductTable},
        props: {page: (route: Route) => addClientId(route, {})},
        meta: {
            requiredPermission: "product:read",
        }
    },
    {
        path: routePrefix + "legacy-products/:productId",
        name: "manageLegacyProduct",
        components: {page: ManageLegacyProduct},
        props: {
            page: (route: Route) => addClientId(route, {productId: route.params.productId}),
        },
        meta: {
            requiredPermission: "product:read",
        }
    },
    {
        path: routePrefix + "settings",
        components: {page: Settings},
        name: "settings",
        children: [
            {
                path: "templates",
                name: "templates",
                components: {page: Settings, tab: TemplateTable},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                },
                meta: {
                    requiredPermission: "template:read"
                }
            },
            {
                path: "templates/create",
                name: "createTemplate",
                components: {page: Settings, tab: TemplateTable, tabModal: CreateOrUpdateTemplate},
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "template:create",
                    underlayName: "templates"
                }
            },
            {
                path: "templates/:templateId/update",
                name: "updateTemplate",
                components: {page: Settings, tab: TemplateTable, tabModal: CreateOrUpdateTemplate},
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route, {
                        templateId: route.params.templateId,
                    })
                },
                meta: {
                    requiredPermission: "template:update",
                    underlayName: "templates"
                }
            },
            {
                path: "addresses",
                name: "addresses",
                components: {page: Settings, tab: AddressTable},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                },
                meta: {
                    requiredPermission: "address:read"
                }
            },
            {
                path: "addresses/create",
                name: "createAddress",
                components: {page: Settings, tab: AddressTable, tabModal: CreateOrUpdateAddress},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {}),
                },
                meta: {
                    requiredPermission: "address:create",
                    underlayName: "addresses"
                }
            },
            {
                path: "addresses/:addressId/update",
                name: "updateAddress",
                components: {page: Settings, tab: AddressTable, tabModal: CreateOrUpdateAddress},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {addressId: route.params.addressId}),
                },
                meta: {
                    requiredPermission: "address:update",
                    underlayName: "addresses"
                }
            },
            {
                path: "order-sources",
                name: "orderSources",
                components: {page: Settings, tab: OrderSourceTable},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                },
                meta: {
                    requiredPermission: "order-source:read",
                }
            },
            {
                path: "order-sources/select-type",
                name: "selectOrderSourceType",
                components: {page: Settings, tab: OrderSourceTable, tabModal: SelectOrderSourceType},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {})
                },
                meta: {
                    requiredPermission: "order-source:create",
                    underlayName: "orderSources",
                }
            },
            {
                path: "carriers",
                name: "carriers",
                components: {page: Settings, tab: CarrierTable},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                },
                meta: {
                    requiredPermission: "carrier-account:read",
                }
            },
            {
                path: "carriers/select-type",
                name: "selectCarrierType",
                components: {page: Settings, tab: CarrierTable, tabModal: SelectCarrierType},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {})
                },
                meta: {
                    requiredPermission: "carrier-account:create",
                    underlayName: "carriers",
                }
            },
            {
                path: "shipping-info",
                name: "shippingInfo",
                components: {page: Settings, tab: ManageShippingInfo},
                props: {
                    tab: (route: Route) => addClientId(route, {})
                },
                meta: {
                    requiredPermission: "client-shipping-info:read"
                }
            },
            {
                path: "sku-maps",
                name: "skuMaps",
                components: {page: Settings, tab: SkuMapTable},
                props: {
                    tab: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "sku-map:read"
                }
            },
            {
                path: "sku-maps/create",
                name: "createSkuMap",
                components: {page: Settings, tab: SkuMapTable, tabModal: CreateSkuMap},
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "sku-map:create",
                    underlayName: "skuMaps",
                }
            },
            {
                path: "courier-maps",
                name: "courierMaps",
                components: {page: Settings, tab: CourierMapTable},
                props: {
                    tab: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "courier-map:read"
                }
            },
            {
                path: "courier-maps/create",
                name: "createCourierMap",
                components: {page: Settings, tab: CourierMapTable, tabModal: CreateCourierMap},
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "courier-map:create",
                    underlayName: "courierMaps",
                }
            },
            {
                path: "tokens",
                name: "apiTokens",
                components: {
                    page: Settings,
                    tab: ApiTokenTable
                },
                props: {
                    tab: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "api-token:read"
                }
            },
            {
                path: "usages",
                name: "usages",
                components: {
                    page: Settings,
                    tab: Usages
                },
                props: {
                    tab: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "metrics:read"
                }
            },
            {
                path: "tokens/create",
                name: "createApiToken",
                components: {
                    page: Settings,
                    tab: ApiTokenTable,
                    tabModal: CreateOrUpdateApiToken,
                },
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "api-token:create",
                    underlayName: "apiTokens"
                }
            },
            {
                path: "tokens/:tokenId/update",
                name: "updateApiToken",
                components: {
                    page: Settings,
                    tab: ApiTokenTable,
                    tabModal: CreateOrUpdateApiToken,
                },
                props: {
                    tab: (route: Route) => addClientId(route),
                    tabModal: (route: Route) => addClientId(route, {tokenId: route.params.tokenId})
                },
                meta: {
                    requiredPermission: "api-token:update",
                    underlayName: "apiTokens"
                }
            },
            {
                path: "features",
                name: "settingsFlags",
                components: {
                    page: Settings,
                    tab: ManageSettings
                },
                props: {
                    tab: (route: Route) => addClientId(route)
                },
                meta: {
                    requiredPermission: "settings:write"
                }
            },
        ]
    },
    {
        path: "settings/order-sources/create/:type",
        name: "createOrderSource",
        components: {page: CreateOrUpdateOrderSource},
        props: {
            page: (route: Route) => addClientId(route, {type: +route.params.type})
        },
        meta: {
            requiredPermission: "order-source:create"
        }
    },
    {
        path: "settings/order-sources/auth",
        name: "authOrderSource",
        components: {page: AuthOrderSource},
        props: {
            page: (route: Route) => addClientId(route, {orderSourceId: (route.query.orderSourceId ?? "") as string})
        },
        meta: {
            requiredPermission: "order-source:update"
        }
    },
    {
        path: "settings/order-sources/:orderSourceId/update",
        name: "updateOrderSource",
        components: {page: CreateOrUpdateOrderSource},
        props: {
            page: (route: Route) => addClientId(route, {orderSourceId: route.params.orderSourceId})
        },
        meta: {
            requiredPermission: "order-source:update"
        }
    },
    {
        path: "settings/carriers/create/:type",
        name: "createCarrier",
        components: {page: CreateOrUpdateCarrier},
        props: {
            page: (route: Route) => addClientId(route, {type: +route.params.type})
        },
        meta: {
            requiredPermission: "carrier-account:create"
        }
    },
    {
        path: "settings/carriers/auth",
        name: "authCarrier",
        components: {page: AuthCarrier},
        props: {
            page: (route: Route) => addClientId(route, {carrierId: (route.query.carrierId ?? "") as string})
        },
        meta: {
            requiredPermission: "carrier-account:update"
        }
    },
    {
        path: "settings/carriers/:carrierId/services",
        name: "manageCarrierServices",
        components: {page: ManageCarrierServices},
        props: {
            page: (route: Route) => addClientId(route, {carrierId: route.params.carrierId})
        },
        meta: {
            requiredPermission: "carrier-account:update"
        }
    },
    {
        path: "settings/carriers/:carrierId/services/create",
        name: "createCarrierService",
        components: {page: ManageCarrierServices, modal: CreateOrUpdateCarrierService},
        props: {
            page: (route: Route) => addClientId(route, {carrierId: route.params.carrierId}),
            modal: (route: Route) => addClientId(route, {carrierId: route.params.carrierId})
        },
        meta: {
            requiredPermission: "carrier-account:update",
            getReturnLocation(): RawLocation {
                return {name: "manageCarrierServices"};
            }
        }
    },
    {
        path: "settings/carriers/:carrierId/services/:serviceId/update",
        name: "updateCarrierService",
        components: {page: ManageCarrierServices, modal: CreateOrUpdateCarrierService},
        props: {
            page: (route: Route) => addClientId(route, {carrierId: route.params.carrierId}),
            modal: (route: Route) => addClientId(route, {carrierId: route.params.carrierId, serviceId: route.params.serviceId})
        },
        meta: {
            requiredPermission: "carrier-account:update",
            getReturnLocation(): RawLocation {
                return {name: "manageCarrierServices"};
            }
        }
    },
    {
        path: "settings/carriers/:carrierId/update",
        name: "updateCarrier",
        components: {page: CreateOrUpdateCarrier},
        props: {
            page: (route: Route) => addClientId(route, {carrierId: route.params.carrierId})
        },
        meta: {
            requiredPermission: "carrier-account:update"
        }
    },
    {
        path: routePrefix + "admin",
        components: {page: Admin},
        name: "admin",
        children: [
            {
                path: "clients",
                name: "clients",
                components: {page: Admin, tab: ClientTable},
                meta: {
                    requiredPermission: "client:read",
                    title: "Clients"
                }
            },
            {
                path: "clients/:clientId/features",
                name: "features",
                components: {page: Admin, tab: ManageFeatureFlags},
                props: {
                    tab: (route: Route) => ({clientId: route.params.clientId})
                },
                meta: {
                    requiredPermission: "feature:write"
                }
            },
            {
                path: "templates",
                name: "adminTemplates",
                components: {page: Admin, tab: AdminTemplateTable},
                props: {tab: (route: Route) => addClientId(route, {})},
                meta: {
                    requiredPermission: "shareable-template:update",
                    title: "Admin Templates"
                }
            },
            {
                path: "templates/create",
                name: "adminCreateTemplate",
                components: {page: Admin, tab: AdminTemplateTable, tabModal: CreateOrUpdateTemplate},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {shareable: true}),
                },
                meta: {
                    requiredPermission: "shareable-template:update",
                    underlayName: "adminTemplates"
                }
            },
            {
                path: "templates/:templateId",
                name: "manageTemplateAccess",
                components: {page: Admin, tab: ManageTemplateAccess},
                props: {
                    tab: (route: Route) => addClientId(route, {templateId: route.params.templateId}),
                },
                meta: {
                    requiredPermission: "template:update-access",
                }
            },
            {
                path: "templates/:templateId/update",
                name: "adminUpdateTemplate",
                components: {page: Admin, tab: AdminTemplateTable, tabModal: CreateOrUpdateTemplate},
                props: {
                    tab: (route: Route) => addClientId(route, {}),
                    tabModal: (route: Route) => addClientId(route, {templateId: route.params.templateId}),
                },
                meta: {
                    requiredPermission: "shareable-template:update",
                    underlayName: "adminTemplates"
                }
            }
        ]
    },
    {
        path: routePrefix + "login",
        name: "login",
        components: {page: Login},
        meta: {
            title: "Login",
            allowAnonymous: true
        }
    }
];
