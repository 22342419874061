










import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class Icon extends Vue {
    @Prop({type: String}) readonly href!: string;
    @Prop({type: Object}) readonly to!: object;
}
