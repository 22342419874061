import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Guid} from "guid-typescript";
import {FeatureApi} from "@/api/v1/FeatureApi";

@Module
class Features extends VuexModule {
    features: string[] = [];

    @Mutation
    setFeatures(features: string[]) {
        this.features = features;
    }

    @Action({ commit: "setFeatures"})
    retrieveFeatures(clientId: Guid) {
        return FeatureApi.getEnabled(clientId);
    }

    get getFeatures(): string[] {
        return this.features;
    }
}

export default Features;
